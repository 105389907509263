<template>
  <FormWrapper
      :form="form"
      :hideForm="hideForm"
      :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    >
    <b-row>
      <b-col>
        <validation-observer
          :ref="`${form.id}-form`"
          v-slot="{ invalid }"
          @focus="handleActiveModule(form.id)"
          v-if="customFormEdit || canEdit"
        >
          <b-row align-v="end">
            <b-col cols="6">
              <div class="form-group">
                <validation-provider
                  name="antecedentes"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    v-if="!fields.custom"
                    v-model="fields.antecedente"
                    id="antecedente-familiares"
                    :options="antecedentes"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    :showNoResults="true"
                    placeholder="Pesquisar antecedente"
                    class="search-mode with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                    :disabled="!canEdit"
                  >
                    <template slot="caret">
                      <div class="search">
                        <Search />
                      </div>
                    </template>
                    <template slot="option" slot-scope="{ option }">
                      <p style="overflow: hidden">{{ option }}</p>
                    </template>
                    <template slot="noResult" slot-scope="props">
                      <li @click="setCustom(true, props.search)">
                        <div class="multiselect__option custom-item">
                          <Plus class="icon" />
                          Adicionar antecedente "{{ props.search }}"
                        </div>
                      </li>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                  </multiselect>
                  <div class="custom-input" v-else>
                    <b-button variant="link" @click="setCustom(false)">
                      Selecionar um item da lista
                    </b-button>
                    <input
                      autocomplete="off"
                      v-model="fields.antecedente"
                      :readonly="!canEdit"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                    />
                  </div>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="form-group">
                <validation-provider
                  name="familiar"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    v-model="fields.familiar"
                    id="familiar"
                    :options="['Mãe', 'Pai', 'Avó', 'Avô', 'Irmã(o)', 'Tio(a)', 'Filho(a)']"
                    :close-on-select="false"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :multiple="true"
                    placeholder="Selecionar familiar"
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                    :disabled="!canEdit"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="selection" slot-scope="{ values }">
                      {{ values?.join(', ') }}
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="primary"
                block
                class="h-38 mt-27 button"
                :disabled="invalid"
                @click="saveItem"
              >
                Adicionar
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col>
            <table class="table-eyecare">
              <thead>
                <tr>
                  <th width="51%">Nome</th>
                  <th>Presente em</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) of form.value" :key="index">
                  <td
                    v-b-tooltip.hover :title="value?.antecedente?.length >= 120 ? value.antecedente : ''"
                  >
                    {{ value?.antecedente?.length >= 120 ? value.antecedente.slice(0, 120)+'...' : value.antecedente }}
                  </td>                  
                  <td>{{ value.familiar?.join(', ') }}</td>
                  <td class="text-right">
                    <div class="more-with-menu">
                      <MoreVertical class="more-icon" />
                      <div class="menu">
                        <b-button
                          variant="link"
                          @click="editItem(value, index)"
                          :disabled="!canEdit"
                        >
                          Editar
                        </b-button>
                        <b-button
                          variant="link remove mb-0"
                          @click="removeItem(index)"
                          :disabled="!canEdit"
                        >
                          Remover
                        </b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  components: {
    FormWrapper,
    Search,
    Plus,
    ChevronDown,
    MoreVertical
  },
  inject: ['antecedentes'],
  data() {
    return {
      fields: {
        custom: false,
        antecedente: null,
        familiar: null
      },
    }
  },
   computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      customFormEdit: state => state.attendance.customFormEdit,
      form: state => state.attendance.form.antecedentesFamiliares,
    })
  },  
  methods: {
    ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/antecedentesFamiliares', ['handleFields', 'handleProps']),
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.antecedente = value ? searchTerm : null
    },
    saveItem() {
      if (this.editingIndex === null && this.form.value.some(v => v.antecedente.toLowerCase() === this.fields.antecedente.toLowerCase())) {
        this.$toast.warning('Antecedente familiar já adicionado')
        return
      }
      
      let value = [...this.form.value]
      if (this.editingIndex && this.editingIndex >= 0) {
        value.splice(this.editingIndex, 1)
      }
      value = [...value]
      this.fields.ultimaAtualizacao = this.moment().format('DD/MM/YYYY')
      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)

      this.fields = {
        custom: false,
        antecedente: null,
        familiar: null
      }

      this.editingIndex = null
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
    }
  },
}
</script>
<style lang="scss" scoped>
.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
  .multiselect__single {
    @media(min-height: 1600){
      font-size: 1.6vh;
    }
    @media(max-height: 1600){
      font-size: 1vw;
    }
  }
}
.button {
  height: 38px;
  margin-bottom: 10px;
}
</style>
